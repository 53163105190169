import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
// import { BLOCKS, MARKS } from "@contentful/rich-text-types"

import Layout from "../components/layout"
import Head from "../components/head"

// %%% For wpgraphql-powered WP site %%%%%%%%%%%%%%%%%%%%%%%%
export const query = graphql`
query ($uri: String!) {
  wpgraphql  {
  pageBy(uri: $uri ){
    slug
    title
    content
  }
  }
}
`

const Page = props => {

  const document = {
    nodeType: "document",
    content: [
      {
        nodeType: "embedded-entry-block",
        content: [
          // why do I have to use content ?
          {
            nodeType: "text",
            value: "",
            marks: [{ type: "" }],
          }
        ],
      }
    ],
  }

  const str = props.data.wpgraphql.pageBy.content

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        // "embedded-asset-block": node => { // doesn't work !  (try -entry- ?)
        // What is node and why it doesnt pas information ? Probably linked to another component => not fully understood !
        const alt = props.data.wpgraphql.pageBy.title
        const imageurl = str.substring( str.indexOf('src=') + 5, str.indexOf(`.jpg"`) + 4 )
        return <img alt={alt} src={imageurl} />
      },
    },
  }

const subtitle = str.substring( str.indexOf('<h4>') + 4, str.indexOf(`</h4>`) - 1 )
const description = str.substring( str.indexOf('</h4>')+9, str.indexOf(`Tarifs`) -4 )

  return (
    <Layout>
      <Head title={props.data.wpgraphql.pageBy.title} />
      <h1> {props.data.wpgraphql.pageBy.title} </h1> 
      {documentToReactComponents(document, options)}
      <h2> {subtitle} </h2>
      {description}
    </Layout>
  )
}

export default Page

